import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserIcon from '../icons/UserIcon';
import { userProfileApi } from '../util/envConfig';

const UserProfile = ({ userData }) => {
	const [user, setUser] = useState(null);
	const navigate = useNavigate();

	const handleLogout = () => {
		localStorage.clear();
		navigate('/');
	};

	const fetchUserData = async () => {
		try {
			const response = await fetch(
				`${userProfileApi}?studentId=${userData?.userId}`,
				{
					headers: {
						Authorization: `Bearer ${userData.accessToken}`,
					},
				}
			);

			if (response.ok) {
				const data = await response.json();
				setUser(data);
			} else {
				console.error('Failed to fetch user data');
			}
		} catch (error) {
			console.error('An error occurred while fetching user data:', error);
		}
	};

	useEffect(() => {
		fetchUserData();
	}, []);

	return (
		<div className="bg-sfBorder">
			<div className="w-full xl:w-[1200px] relative mx-auto px-4 md:px-0 py-5 md:py-8 min-h-screen">
				<div className="mb-5">
					<button
						className="bg-sfSecondaryColor hover:bg-mediumGreen cursor-pointer text-sm text-white font-medium py-2.5 px-3 rounded-md"
						onClick={() => navigate(-1)}
					>
						Back
					</button>
				</div>
				<div className="bg-white shadow-xl py-6 px-5 rounded-xl w-full md:w-4/12 mx-auto">
					<div className="bg-white border-4 rounded-full border-sfBorder w-[110px] h-[110px] mx-auto mb-2.5 flex justify-center items-center">
						<UserIcon className="w-[80px] h-[80px]" fill="#000" />
					</div>

					<h4 className="text-xl font-semibold text-footerColor2 text-center mb-7">
						{user?.firstName}
					</h4>
					<ul className="my-4 space-y-3">
						<li>
							<a className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
								<span className="flex-1 ms-3 whitespace-nowrap">
									Images Uploaded
								</span>
								<span className="inline-flex items-center justify-center px-3 py-1 ms-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400">
									{user?.imageCount}
								</span>
							</a>
						</li>
						<li>
							<a className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
								<span className="flex-1 ms-3 whitespace-nowrap">
									Videos Uploaded
								</span>
								<span className="inline-flex items-center justify-center px-3 py-1 ms-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400">
									{user?.reelCount}
								</span>
							</a>
						</li>

						<li>
							<a className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
								<span className="flex-1 ms-3 whitespace-nowrap">
									Article Uploaded
								</span>
								<span className="inline-flex items-center justify-center px-3 py-1 ms-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400">
									{user?.articleCount}
								</span>
							</a>
						</li>
					</ul>
					<div className="flex flex-col md:flex-row items-center mb-4">
						<p className="text-sm font-normal text-disableText md:mr-1.5 w-full md:w-3/12">
							User Name:
						</p>
						<p className="text-sm font-semibold text-black w-full md:w-9/12">
							{user?.userName}
						</p>
					</div>
					<div className="flex flex-col md:flex-row items-center mb-4">
						<p className="text-sm font-normal text-disableText md:mr-1.5 w-full md:w-3/12">
							College Name:
						</p>
						<p className="text-sm font-semibold text-black w-full md:w-9/12">
							{user?.collegeName}
						</p>
					</div>
					<div className="flex flex-col md:flex-row items-center mb-4">
						<p className="text-sm font-normal text-disableText md:mr-1.5 w-full md:w-3/12">
							University Name:
						</p>
						<p className="text-sm font-semibold text-black w-full md:w-9/12">
							{user?.universityName}
						</p>
					</div>

					<div className="w-full flex flex-row items-center mt-5">
						<button
							className="w-full bg-sfPrimaryColor hover:bg-sfSecondaryColor text-sm font-semibold text-white py-2.5 px-3 rounded-md"
							onClick={handleLogout}
						>
							Logout
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserProfile;
