import React from 'react';

const Footer = () => {
	return (
		<div className="flex justify-center items-center bg-white h-[48px] px-3 py-2.5 border-t border-disableColor shadow-xl">
			<p className="text-sm font-normal text-black text-center">
				Copyright &copy; 2023 BigHaat Agro Private Limited
			</p>
		</div>
	);
};

export default Footer;
