/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import HeaderOld from '../components/HeaderOld';
import axios from 'axios';
import { showToast } from '../util/index';
import { loginApi } from '../util/envConfig';
import EyeHideIcon from '../icons/EyeHideIcon';
import EyeIcon from '../icons/EyeIcon';

const LogIn = () => {
	const [errMsg, setErrMsg] = useState('');
	const [viewPassword, setViewPassword] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const navigate = useNavigate();

	const handleLoginFormSubmit = async (data) => {
		try {
			const requestData = {
				userName: data.email,
				password: data.password,
			};
			const response = await axios.post(loginApi, requestData);
			const responseData = response.data;
			if (responseData) {
				localStorage.setItem('userData', JSON.stringify(responseData));

				navigate('/home');
			} else {
				setErrMsg(responseData.message);
			}
		} catch (error) {
			showToast('Incorrect User Name or Password', 'error');
			console.error('Error while logging in:', error);
		}
	};

	return (
		<section className="w-full h-screen overflow-hidden bg-gradient-to-tr from-footerColor2 to-[#daf2d8] flex flex-col justify-center lg:justify-normal px-4 lg:px-0 pb-4 lg:pb-0">
			<div className="mt-4 lg:ml-5 mb-5">
				<img src="bighaat-white-logo.png" className="w-[140px] h-[52px]" />
			</div>
			<div className="w-full h-auto lg:w-[85%] bg-white rounded-3xl p-5 mx-auto shadow-2xl lg:h-[80%] flex flex-col lg:flex-row">
				<div className="w-full md:w-7/12 flex justify-center items-center mb-5 lg:mb-0">
					<img
						src="plant-disease-img.png"
						className="w-[300px] h-[200px] lg:w-[600px] lg:h-[400px]"
					/>
				</div>
				<div className="w-full md:w-1/12"></div>
				<div className="w-full md:w-4/12 items-center flex">
					<div className="w-full bg-sectionColor1 p-4 rounded-2xl h-auto">
						<h3 className="text-2xl font-semibold text-black mb-5">Login</h3>
						<form
							className="mt-4"
							onSubmit={handleSubmit(handleLoginFormSubmit)}
						>
							{errMsg && (
								<p className="text-RedOrange font-normal py-2 text-sm">
									{errMsg}
								</p>
							)}
							<div className="mb-5">
								<label
									htmlFor="email"
									className="block text-sm font-normal text-disableText"
								>
									User Name
									<input
										type="email"
										id="email"
										className="block w-full px-3 py-2.5 mt-1 bg-white border border-borderColor rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40 text-sm font-normal text-black"
										//   ref={fieldRef}
										autoComplete="off"
										{...register('email', {
											required: true,
											pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
										})}
										required
									/>
								</label>
								{errors.email && errors.email.type === 'required' && (
									<p className="errMsg">Email is required.</p>
								)}
								{errors.email && errors.email.type === 'pattern' && (
									<p className="errMsg">Email is not valid.</p>
								)}
							</div>

							<div className="mb-5">
								<label
									htmlFor="password"
									className="block text-sm font-normal text-disableText"
								>
									Password
									<div className="relative flex items-center">
										<input
											id="password"
											type={viewPassword ? 'text' : 'password'}
											className="block w-full px-3 py-2.5 mt-1 bg-white border border-borderColor rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40 text-sm font-normal text-black"
											{...register('password', {
												required: true,
												minLength: 4,
											})}
											autoComplete="off"
											required
										/>
										<button
											type="button"
											className="absolute right-3 top-[14px]"
											onClick={() => setViewPassword(!viewPassword)}
										>
											{viewPassword ? <EyeIcon /> : <EyeHideIcon />}
										</button>
									</div>
								</label>
								{errors.password && errors.password.type === 'required' && (
									<p className="errMsg">Password is required.</p>
								)}
								{errors.password && errors.password.type === 'minLength' && (
									<p className="errMsg">
										Password should be at-least 4 characters.
									</p>
								)}
							</div>

							<div className="mt-8">
								<button
									type="submit"
									className="w-full mb-4 px-3 py-2.5 text-sm font-medium text-white transition-colors duration-200 transform bg-sfPrimaryColor rounded-md hover:bg-sfSecondaryColor focus:outline-none focus:bg-sfSecondaryColor tracking-wider"
								>
									LOGIN
								</button>
							</div>
						</form>

						<div className="flex flex-col items-center justify-center mt-1.5">
							<Link
								to="/forgot-password"
								className="no-underline hover:underline mb-4 text-sm text-disableText"
							>
								Forgot your password?
							</Link>
							<hr className="w-full border-t border-gray-300 mb-3" />
						</div>

						<div className="flex flex-row items-center justify-center mt-1.5">
							<span className="text-sm font-medium text-grayColor mr-2">
								Don't have an account?
							</span>
							<Link
								to="/new-user"
								className="text-sm font-medium text-sfSecondaryColor transition duration-300"
							>
								Sign up
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default LogIn;
