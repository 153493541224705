import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { showToast } from '../util/index';
import axios from 'axios';

import {
	uploadReelApi,
	categoriesListApi,
	saveReelApi,
} from '../util/envConfig';
import ProgressBar from '../components/ProgressBar';

const ReelUploadPage = () => {
	const [selectedFile, setSelectedFile] = useState();
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [value, setValue] = useState(0);
	const [success, setSuccess] = useState(false);
	const [uploading, setUploading] = useState(false);

	const [filePreviewUrl, setFilePreviewUrl] = useState(null);
	const [imageKey, setImageKey] = useState(0);
	const [duration, setDuration] = useState(0);

	const [categoryOptions, setCategoryOptions] = useState([]);
	const [loadingCropOptions, setLoadingCropOptions] = useState(true);

	const storedUserData = localStorage.getItem('userData');
	const userData = JSON.parse(storedUserData);

	const navigate = useNavigate();

	const maxFileSize = 100 * 1024 * 1024;
	const maxDuration = 60;

	const handleFileChange = async (event) => {
		const file = event?.target?.files?.[0];

		setSelectedFile(file);
		if (/^image\//.test(file.type)) {
			setDuration(0);
			setFilePreviewUrl(null);

			return;
		}

		const durationInSeconds = await getVideoDuration(file);
		setDuration(durationInSeconds);

		const previewUrl = URL.createObjectURL(file);
		setFilePreviewUrl(previewUrl);
	};

	const handleCategoryChange = (selectedOption) => {
		setSelectedCategory(selectedOption);
	};

	const clearSelection = () => {
		setSelectedCategory(null);
		setSelectedFile();
		setFilePreviewUrl(null);
		setImageKey(imageKey + 1);
	};

	const getVideoDuration = (file) => {
		return new Promise((resolve) => {
			const video = document.createElement('video');
			video.preload = 'metadata';
			video.onloadedmetadata = () => {
				resolve(video.duration);
				URL.revokeObjectURL(video.src);
			};
			video.src = URL.createObjectURL(file);
		});
	};

	useEffect(() => {
		const fetchCatagoryOptions = async () => {
			try {
				const response = await axios.get(categoriesListApi, {
					headers: {
						Authorization: `Bearer ${userData?.accessToken}`,
					},
				});
				if (response.data) {
					const options = response?.data.map((catagory) => ({
						value: catagory?.id,
						label: catagory?.topicName,
					}));

					setCategoryOptions(options);
					setLoadingCropOptions(false);
				}
			} catch (error) {
				console.error('Error fetching Category options:', error);
			}
		};

		fetchCatagoryOptions();
	}, []);

	const handleUpload = () => {
		if (selectedFile) {
			if (selectedFile.size > maxFileSize) {
				showToast('File size exceeds the maximum limit', 'error');
				return;
			}
		}
		if (selectedFile) {
			if (duration > maxDuration) {
				showToast('File Duration exceeds the maximum limit', 'error');
				return;
			}
		}
		if (!selectedFile || !selectedCategory) {
			showToast('Please Fill All Mandatory Fields', 'error');
			return;
		}
		if (!duration) {
			showToast('Please select a video file (MP4, MKV, etc.)', 'error');
			return;
		}

		setUploading(true);

		const xhr = new XMLHttpRequest();

		xhr.upload.addEventListener('progress', (event) => {
			if (event.lengthComputable) {
				const progress = event.loaded / event.total;
				setValue(progress);
			}
		});

		xhr.onreadystatechange = () => {
			if (xhr.readyState === XMLHttpRequest.DONE) {
				if (xhr.status === 200) {
					showToast('File uploaded successfully.', 'success');
					setSuccess(true);
					setSelectedFile(null);
					setFilePreviewUrl(null);
					setUploading(false);
					setImageKey(imageKey + 1);
					setSelectedCategory(null);

					const uploadedFileUrl = JSON.parse(xhr.responseText).reelUrl;

					const saveReelPayload = {
						userId: userData?.userId,
						categoryId: selectedCategory?.value,
						url: uploadedFileUrl,
						additionalInfo: {},
					};

					axios
						.post(saveReelApi, saveReelPayload, {
							headers: {
								Authorization: `Bearer ${userData.accessToken}`,
							},
						})
						.then((saveReelResponse) => {
							console.log('Reel saved successfully:', saveReelResponse.data);
						})
						.catch((saveReelError) => {
							console.error('Error saving Reel:', saveReelError);
						});
				} else {
					showToast('Error uploading file', 'error');
					console.error('Error uploading file:', xhr.statusText);
					setSuccess(false);
					setValue(0);
				}

				setUploading(false);
				setSuccess(false);
				setValue(0);
			}
		};

		xhr.open('POST', uploadReelApi, true);
		xhr.setRequestHeader('Authorization', `Bearer ${userData.accessToken}`);

		const formData = new FormData();
		formData.append('reel', selectedFile);
		xhr.send(formData);
	};

	return (
		<div className="bg-sfBorder">
			<div className="w-full xl:w-[1200px] mx-auto px-4 md:px-0 py-5 md:py-8 flex flex-col">
				<div className="mb-5">
					<button
						className="bg-sfSecondaryColor hover:bg-mediumGreen cursor-pointer text-sm text-white font-medium py-2.5 px-3 rounded-md"
						onClick={() => navigate(-1)}
					>
						Back
					</button>
				</div>
				<div className="min-h-screen flex items-center">
					<div className="w-full md:w-5/12 mx-auto p-5 bg-white shadow-xl rounded-2xl">
						<h3 className="text-2xl font-medium text-black mb-4">
							Upload Reel
						</h3>
						<div className="mb-4">
							<label
								htmlFor="catagory"
								className="block text-sm font-normal text-black mb-1"
							>
								Select Reel Category<span style={{ color: 'red' }}>*</span>
							</label>

							{loadingCropOptions ? (
								'Loading Catagory options...'
							) : (
								<Select
									options={categoryOptions}
									value={selectedCategory}
									onChange={handleCategoryChange}
									className="w-full text-sm font-normal text-black"
								/>
							)}
						</div>

						<div className="mb-4">
							<label className="block text-sm font-normal text-black mb-1">
								Select Reel Video (MP4, MKV, etc.)
								<span style={{ color: 'red' }}>*</span>
								<div>
									<small className="block text-xs font-normal text-disableText">
										Maximum size: 100 MB, Maximum duration: 60 seconds.
									</small>
								</div>
							</label>
							<input
								key={imageKey}
								type="file"
								onChange={handleFileChange}
								accept="video/*"
								className="custom-file-upload w-full h-11 py-1 px-3 border border-borderColor rounded-md text-sm font-normal text-black"
							/>
						</div>

						<div className="mb-4">
							{filePreviewUrl && (
								<div>
									<h6 className="text-base font-semibold text-black">
										File Preview:
									</h6>

									<div className="mb-2">
										{selectedFile && (
											<>
												{selectedFile.size > maxFileSize && (
													<p className="text-xs font-normal text-redColorLine">
														File size exceeds the maximum limit.
													</p>
												)}
												{duration > maxDuration && (
													<p className="text-xs font-normal text-redColorLine">
														File duration exceeds the maximum limit (60
														seconds).
													</p>
												)}
											</>
										)}
									</div>

									<video
										controls
										className="w-full md:h-60 h-40"
										src={filePreviewUrl}
										alt="Reel Preview"
									/>
								</div>
							)}
						</div>
						<div>
							{uploading && (
								<div className="flex items-center">
									<div className="w-full mr-2">
										<ProgressBar value={value} />
									</div>
									<p className="text-sm font-normal text-sfPrimaryColor">
										{success ? 'Complete!' : 'Uploading...'}
									</p>
								</div>
							)}
						</div>

						<div className="flex flex-row items-center mt-5">
							<button
								onClick={clearSelection}
								disabled={uploading}
								className="w-full md:w-1/2 bg-disableText hover:bg-opacity-90 text-sm font-medium text-white py-2.5 px-3 rounded-md mr-2"
							>
								Clear
							</button>

							<button
								onClick={handleUpload}
								disabled={uploading}
								className="w-full md:w-1/2 bg-footerColor2 hover:bg-green500 text-sm font-medium text-white py-2.5 px-3 rounded-md ml-2"
							>
								Upload
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReelUploadPage;
