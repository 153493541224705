import React from 'react';
const UserIcon = ({
	height = 24,
	width = 24,
	fill = '',
	className = '',
	strokeWidth = '',
}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		height={height}
		width={width}
		fill={fill}
		className={className}
		strokeWidth={strokeWidth}
	>
		<g>
			<path d="M2,20.9c0.1-1.8,0.7-3.4,1.8-4.8c1.3-1.7,3-3,5.1-3.6c0.6-0.2,1.3-0.3,1.9-0.3c0.6,0,1.2,0,1.9,0c2.2-0.1,4.1,0.7,5.8,2 c1.7,1.4,2.8,3.1,3.3,5.2c0.1,0.5,0.2,1,0.2,1.6c0,0.6-0.3,1-0.9,1c-0.5,0-0.9-0.4-0.9-0.9c0-0.5-0.1-1-0.2-1.5 c-0.6-2-1.9-3.5-3.7-4.6c-1.1-0.6-2.2-0.9-3.5-0.9c-0.5,0-1.1,0-1.6,0C9.5,14,8,14.6,6.6,15.6c-1.4,1.1-2.4,2.6-2.7,4.4 c-0.1,0.4-0.1,0.7-0.1,1.1c0,0.5-0.3,0.8-0.7,0.9c-0.4,0.1-0.7-0.1-0.9-0.4C2,21.3,2,21.1,2,20.9z" />
			<path d="M12,11.7c-2.8,0-4.9-2.1-4.9-4.9C7.1,4.1,9.3,2,12,2c2.8,0,4.9,2.1,4.9,4.9C16.9,9.6,14.8,11.7,12,11.7z M12,3.8 c-1.8,0-3.1,1.3-3.1,3.1c0,1.8,1.3,3.1,3.1,3.1c1.8,0,3.1-1.3,3.1-3.1C15.1,5.1,13.8,3.8,12,3.8z" />
		</g>
	</svg>
);
export default UserIcon;
