/* API base urls */
const apiBaseUrlsObj = {
	dev: 'https://ugcdev.devbh.in',
	qa: 'https://ugc.qabh.in',
	beta: 'https://ugc.betabh.in',
	prod: 'https://ugcprod.bighaat.com',
};

/*
    Change it when you are moving to resp. branch releases
    main branch - PROD
    beta-main - Beta
    dev-main - Dev
*/
export const env = 'prod';

const apiBaseUrl = apiBaseUrlsObj[env];

export const loginApi = apiBaseUrl + '/api/auth/login';
export const registraionApi = apiBaseUrl + '/api/auth/register';
export const saveImageApi = apiBaseUrl + '/api/image/Save';
export const uploadImageApi = apiBaseUrl + '/api/image/upload';
export const uploadReelApi = apiBaseUrl + '/api/reel/upload';
export const saveReelApi = apiBaseUrl + '/api/reel/save';
export const uploadArticleApi = apiBaseUrl + '/api/article/upload';
export const saveArticleApi = apiBaseUrl + '/api/article/save';
export const userProfileApi = apiBaseUrl + '/api/student/profile';
export const cropListApi = apiBaseUrl + '/api/crop/list';
export const categoriesListApi = apiBaseUrl + '/api/category/all';
export const forgotPasswordApi = apiBaseUrl + '/api/auth/forgot/password';
export const updatePasswordApi = apiBaseUrl + '/api/auth/update/password';

// export const blobEnvConfig =
// 	env === 'beta' || env === 'prod'
// 		? 'https://bighaatprodblobv2.blob.core.windows.net/'
// 		: 'https://bighaatdevnewblob.blob.core.windows.net/';
