import React from 'react';
import { Outlet, Navigate, useLocation, useNavigate } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';

const PrivateRoutes = () => {
	const storedUserData = localStorage.getItem('userData');
	const userData = JSON.parse(storedUserData);

	const navigate = useNavigate();
	const location = useLocation();

	let auth = { token: userData?.accessToken };

	const isUserProfilePage = location.pathname === '/user-profile';

	return auth.token ? (
		<section>
			<Header userProfileStyle={isUserProfilePage ? 'hidden' : ''} />
			<div>
				<Outlet />
			</div>
			<Footer />
		</section>
	) : (
		<Navigate to="/" />
	);
};

export default PrivateRoutes;
