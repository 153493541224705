import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { userProfileApi } from '../util/envConfig';

const Home = ({ userData }) => {
	const [user, setUser] = useState(null);
	const [greeting, setGreeting] = useState('');

	const navigate = useNavigate();

	const handleCardClick = (route) => {
		navigate(route);
	};

	const determineGreeting = () => {
		const currentHour = new Date().getHours();
		if (currentHour >= 5 && currentHour < 12) {
			setGreeting('Good Morning');
		} else if (currentHour >= 12 && currentHour < 17) {
			setGreeting('Good Afternoon');
		} else {
			setGreeting('Good Evening');
		}
	};

	const fetchUserData = async () => {
		try {
			const response = await fetch(
				`${userProfileApi}?studentId=${userData?.userId}`,
				{
					headers: {
						Authorization: `Bearer ${userData.accessToken}`,
					},
				}
			);

			if (response.ok) {
				const data = await response.json();
				setUser(data);
			} else {
				console.error('Failed to fetch user data');
			}
		} catch (error) {
			console.error('An error occurred while fetching user data:', error);
		}
	};

	useEffect(() => {
		fetchUserData();
		determineGreeting();
	}, []);

	return (
		<div className="bg-sfBorder">
			<div className="w-full xl:w-[1200px] mx-auto px-4 md:px-0 py-5 md:py-8 md:min-h-screen flex flex-col">
				<div className="flex flex-col items-center mb-5 xl:mt-2.5 xl:mb-10">
					<h2 className="text-3xl font-semibold text-black text-center flex flex-col md:flex-row mb-1">
						<span className="text-black mr-1.5">{greeting},</span>
						<span className="text-sfSecondaryColor">{user?.firstName}</span>
					</h2>
				</div>

				<div className="flex flex-col w-full xl:w-7/12 mx-auto md:flex-row justify-center mb-8">
					<div className="flex flex-col items-center mb-5 xl:mb-0 bg-white py-2.5 px-3 rounded-lg xl:rounded-ss-lg xl:rounded-es-lg xl:rounded-se-none xl:rounded-ee-none border w-full border-sfBorder shadow-md xl:shadow-none">
						<p className="text-sm font-medium text-disableText mb-1">
							Images Uploaded
						</p>
						<h3 className="text-3xl font-normal text-mediumGreen">
							{user?.imageCount}
						</h3>
					</div>
					<div className="flex flex-col items-center mb-5 xl:mb-0 bg-white py-2.5 px-3 rounded-lg xl:rounded-none border w-full border-sfBorder shadow-md xl:shadow-none">
						<p className="text-sm font-medium text-disableText mb-1">
							Videos Uploaded
						</p>
						<h3 className="text-3xl font-normal text-mediumGreen">
							{user?.reelCount}
						</h3>
					</div>

					<div className="flex flex-col items-center bg-white py-2.5 px-3 rounded-lg xl:rounded-se-lg xl:rounded-ee-lg xl:rounded-es-none xl:rounded-ss-none border w-full border-sfBorder shadow-md xl:shadow-none">
						<p className="text-sm font-medium text-disableText mb-1">
							Articles Uploaded
						</p>
						<h3 className="text-3xl font-normal text-mediumGreen">
							{user?.articleCount}
						</h3>
					</div>
				</div>

				<div className="flex w-full justify-center flex-col md:flex-row">
					<div
						className="w-full md:w-3/12 border-t-2 border-gray-300 p-4 cursor-pointer bg-white rounded-xl shadow-lg hover:shadow-xl duration-75 ease-in-out hover:transition-all hover:translate-y-2 mb-5 md:mb-0"
						onClick={() => handleCardClick('/agri-cop')}
					>
						<div>
							<img
								src="upload-img.png"
								alt="Upload disease image"
								className="w-full h-auto"
							/>
						</div>
						<Link className="text-sm font-semibold text-white hover:text-black text-center bg-sfPrimaryColor w-full flex justify-center items-center hover:bg-sfSecondaryColor rounded-md px-3 py-2 mb-5 mt-2.5">
							Upload Images
						</Link>
						<p className="text-sm font-normal text-disableText text-center">
							Share your crop images with us.
						</p>
					</div>
					<div className="w-full md:w-1/12"></div>
					<div
						className="w-full md:w-3/12 border-t-2 border-gray-300 p-4 cursor-pointer bg-white rounded-xl shadow-lg hover:shadow-xl duration-75 ease-in-out hover:transition-all hover:translate-y-2 mb-5 md:mb-0"
						onClick={() => handleCardClick('/reel')}
					>
						<div>
							<img
								src="upload-video-img.png"
								alt="Upload disease video"
								className="w-full h-auto"
							/>
						</div>
						<Link className="text-sm font-semibold text-white hover:text-black text-center bg-sfPrimaryColor w-full flex justify-center items-center hover:bg-sfSecondaryColor rounded-md px-3 py-2 mb-5 mt-2.5">
							Upload Reels
						</Link>
						<p className="text-sm font-normal text-disableText text-center">
							Share your videos with us.
						</p>
					</div>

					<div className="w-full md:w-1/12"></div>
					<div
						className="w-full md:w-3/12 border-t-2 border-gray-300 p-4 cursor-pointer bg-white rounded-xl shadow-lg hover:shadow-xl duration-75 ease-in-out hover:transition-all hover:translate-y-2"
						onClick={() => handleCardClick('/article')}
					>
						<div>
							<img
								src="write-an-article-img.png"
								alt="Write an article"
								className="w-full h-auto"
							/>
						</div>
						<Link className="text-sm font-semibold text-white hover:text-black text-center bg-sfPrimaryColor w-full flex justify-center items-center hover:bg-sfSecondaryColor rounded-md px-3 py-2 mb-5 mt-2.5">
							Upload Article
						</Link>

						<p className="text-sm font-normal text-disableText text-center">
							Publish your articles here.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
