import './styles.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LogIn from './pages/LogIn';
import UserRegistration from './pages/UserRegistration';
import AgriCop from './pages/AgriCop';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ForgotPassword from './pages/ForgotPassword';
import UserProfile from './pages/UserProfile';
import PrivateRoutes from './components/PrivateRoute';
import ArticlePage from './pages/ArticlePage';
import Home from './pages/Home';
import ReelUploadPage from './pages/ReelUploadPage';

function App() {
	const storedUserData = localStorage.getItem('userData');
	const userDataa = JSON.parse(storedUserData);

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<LogIn />} />
				<Route path="/login" element={<LogIn />} />
				<Route path="/forgot-password" element={<ForgotPassword />} />
				<Route path="/new-user" element={<UserRegistration />} />

				<Route element={<PrivateRoutes />}>
					<Route
						path="/user-profile"
						element={<UserProfile userData={userDataa} />}
					/>
					<Route path="/agri-cop" element={<AgriCop />} />
					<Route path="/article" element={<ArticlePage />} />
					<Route path="/home" element={<Home userData={userDataa} />} />
					<Route path="/reel" element={<ReelUploadPage />} />
				</Route>
			</Routes>
			<ToastContainer autoClose={3000} />
		</BrowserRouter>
	);
}

export default App;
