import React from 'react';
const TickIcon = ({
	height = 24,
	width = 24,
	fill = '',
	className = '',
	strokeWidth = '',
}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		height={height}
		width={width}
		fill={fill}
		className={className}
		strokeWidth={strokeWidth}
	>
		<path d="M3.9,11.5c0.1,0,0.1,0.1,0.1,0.1c1.4,1.4,2.8,2.8,4.2,4.2c0.1,0.1,0.2,0.1,0.3,0C12.3,12,16.2,8.2,20,4.4 c0.1-0.1,0.2-0.1,0.3,0c0.5,0.5,1.1,1.1,1.6,1.6c0.1,0.1,0.2,0.2,0,0.3c-4.1,4.1-8.1,8.1-12.2,12.2c-0.4,0.4-0.8,0.8-1.2,1.2 c-0.1,0.1-0.2,0.1-0.3,0c-2-2.1-4.1-4.1-6.2-6.2c-0.1-0.1-0.1-0.1,0-0.3c0.6-0.6,1.1-1.1,1.7-1.7C3.8,11.5,3.8,11.5,3.9,11.5z" />
	</svg>
);
export default TickIcon;
