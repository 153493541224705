/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { showToast } from '../util/index';
import { forgotPasswordApi, updatePasswordApi } from '../util/envConfig.js';

const ForgotPassword = (props) => {
	const [setshowUpdateFields, setSetshowUpdateFields] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errMsg, setErrMsg] = useState('');
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm();
	const navigate = useNavigate();
	const fieldRef = useRef();

	// const handleFormSubmit = async (data) => {
	// 	setErrMsg('');
	// 	setIsLoading(true);

	// 	if (setshowUpdateFields) {
	// 		// Logic for updating password
	// 		if (data.newPassword !== data.retypePassword) {
	// 			setIsLoading(false);
	// 			toast.error('Passwords do not match!');
	// 			return;
	// 		}

	// 		try {
	// 			const response = await fetch(updatePasswordApi, {
	// 				method: 'POST',
	// 				headers: {
	// 					'Content-Type': 'application/json',
	// 				},
	// 				body: JSON.stringify({
	// 					userName: data.email,
	// 					newPassword: data.newPassword,
	// 					otpCode: data.OTP,
	// 				}),
	// 			});

	// 			if (response.ok) {
	// 				setIsLoading(false);
	// 				// toast.success('Password Updated Successfully');
	// 				navigate(`/login`);
	// 			} else {
	// 				const errorData = await response.json();
	// 				setIsLoading(false);
	// 				setErrMsg(errorData.message);
	// 			}
	// 		} catch (err) {
	// 			setIsLoading(false);
	// 			setErrMsg(err.message);
	// 		}
	// 	} else {
	// 		// Logic for initiating the password recovery process
	// 		try {
	// 			const url = `${forgotPasswordApi}?userName=${data.email}`;
	// 			const response = await fetch(url, {
	// 				method: 'POST',
	// 				headers: {
	// 					'Content-Type': 'application/json',
	// 				},
	// 				// No need to include a body in this case
	// 			});

	// 			if (response.ok) {
	// 				setIsLoading(false);
	// 				// toast.success('Password Update Requested Successfully');
	// 				setSetshowUpdateFields(true); // Set the state to true after OTP request
	// 				// Optionally, you can navigate to a confirmation page or display a message
	// 			} else {
	// 				const errorData = await response.json();
	// 				setIsLoading(false);
	// 				setErrMsg(errorData.message);
	// 			}
	// 		} catch (err) {
	// 			setIsLoading(false);
	// 			setErrMsg(err.message);
	// 		}
	// 	}
	// };

	const handleFormSubmit = async (data) => {
		setErrMsg('');
		setIsLoading(true);

		if (setshowUpdateFields) {
			if (data.newPassword !== data.retypePassword) {
				setIsLoading(false);
				toast.error('Passwords do not match!');
				return;
			}

			try {
				const response = await fetch(updatePasswordApi, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						userName: data.email,
						newPassword: data.newPassword,
						otpCode: data.OTP,
					}),
				});

				if (response.ok) {
					setIsLoading(false);
					showToast('Password Updated Successfully', 'success');
					navigate(`/login`);
				} else {
					const errorData = await response.json();
					setIsLoading(false);
					setErrMsg(errorData.error);
				}
			} catch (err) {
				setIsLoading(false);
				setErrMsg(err.message);
			}
		} else {
			try {
				const response = await fetch(forgotPasswordApi, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						userName: data.email,
					}),
				});

				if (response.ok) {
					setIsLoading(false);
					setSetshowUpdateFields(true);
				} else {
					const errorData = await response.json();
					setIsLoading(false);
					setErrMsg(errorData.error);
				}
			} catch (err) {
				setIsLoading(false);
				setErrMsg(err.message);
			}
		}
	};

	return (
		<section className="flex justify-center bg-gradient-to-tr from-footerColor2 to-[#daf2d8] items-center h-screen bg-gray-100 ">
			<div className="absolute top-0 left-0 mt-4 ml-5">
				<img
					src="bighaat-white-logo.png"
					className="w-[140px] h-[52px]"
					alt="Logo"
				/>
			</div>
			<div className="bg-white p-8 rounded-md shadow-lg w-full max-w-md">
				<h1 className="text-3xl font-semibold mb-6 text-gray-800">
					Forgot Password
				</h1>
				<form className="space-y-4">
					{errMsg && <p className="text-red py-2">{errMsg}</p>}
					{setshowUpdateFields ? (
						<>
							<div>
								<label className="block text-sm text-gray-800">
									OTP
									<input
										type="number"
										id="otp"
										className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
										ref={fieldRef}
										autoComplete="off"
										{...register('OTP', {
											required: true,
										})}
										required
									/>
								</label>
								{errors.OTP && errors.OTP.type === 'required' && (
									<p className="errMsg">OTP is required.</p>
								)}
								{errors.OTP && errors.OTP.type === 'pattern' && (
									<p className="errMsg">OTP is not valid.</p>
								)}
							</div>
							<div>
								<label className="block text-sm text-gray-800">
									New password
									<input
										type="password"
										id="password"
										className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
										ref={fieldRef}
										autoComplete="off"
										{...register('newPassword', {
											required: true,
										})}
										required
									/>
								</label>
								{errors.newPassword &&
									errors.newPassword.type === 'required' && (
										<p className="errMsg">Password is required.</p>
									)}
								{errors.newPassword &&
									errors.newPassword.type === 'pattern' && (
										<p className="errMsg">Password is not valid.</p>
									)}
							</div>
							<div>
								<label className="block text-sm text-gray-800">
									Confirm new password
									<input
										type="password"
										id="retypePassword"
										className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
										ref={fieldRef}
										autoComplete="off"
										{...register('retypePassword', {
											required: true,
										})}
										required
									/>
								</label>
								{errors.retypePassword &&
									errors.retypePassword.type === 'required' && (
										<p className="errMsg">Password is required.</p>
									)}
								{errors.retypePassword &&
									errors.retypePassword.type === 'pattern' && (
										<p className="errMsg">Password is not valid.</p>
									)}
							</div>
						</>
					) : (
						<div>
							<label htmlFor="username" className="block text-sm text-gray-800">
								Email
								<input
									type="email"
									id="username"
									className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
									ref={fieldRef}
									autoComplete="off"
									{...register('email', {
										required: true,
										pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
									})}
									required
								/>
							</label>
							{errors.email && errors.email.type === 'required' && (
								<p className="errMsg">Email is required.</p>
							)}
							{errors.email && errors.email.type === 'pattern' && (
								<p className="errMsg">Email is not valid.</p>
							)}
						</div>
					)}

					<div>
						<button
							className={`w-full mb-4 px-3 py-2.5 text-sm font-medium text-white transition-colors duration-200 transform bg-sfPrimaryColor rounded-md hover:bg-sfSecondaryColor focus:outline-none focus:bg-sfSecondaryColor tracking-wider ${
								isLoading ? 'cursor-not-allowed opacity-50' : ''
							}`}
							onClick={handleSubmit(handleFormSubmit)}
							disabled={isLoading}
						>
							{isLoading
								? 'Loading...'
								: setshowUpdateFields
								? 'Update Password'
								: 'Request OTP'}
						</button>
					</div>
				</form>
			</div>
		</section>
	);
};

export default ForgotPassword;
