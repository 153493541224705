import React from 'react';
const EyeIcon = ({
	height = 24,
	width = 24,
	fill = '',
	className = '',
	strokeWidth = '',
}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		height={height}
		width={width}
		fill={fill}
		className={className}
		strokeWidth={strokeWidth}
	>
		<g>
			<path d="M12.1,18.7c-2.1,0-4-0.7-5.6-1.8c-1.6-1-2.9-2.3-4-3.8c-0.5-0.7-0.5-1.6,0-2.2c1.4-1.7,2.9-3.2,4.8-4.3 C8.4,6,9.7,5.5,11.1,5.4c1.7-0.2,3.4,0.1,5,0.9c1.8,0.9,3.4,2.1,4.7,3.6c0.3,0.3,0.5,0.6,0.8,0.9c0.6,0.7,0.6,1.6,0,2.3 C20.4,14.6,19,16,17.4,17c-1.4,0.9-2.8,1.4-4.4,1.6C12.6,18.7,12.3,18.7,12.1,18.7z M12.1,17c1.1,0,2.3-0.3,3.4-0.8 c1.9-0.9,3.4-2.4,4.8-4c0.1-0.1,0.1-0.2,0-0.3C19,10.4,17.7,9.1,16,8.1C14.8,7.4,13.4,7,12,7c-1.1,0-2.2,0.3-3.3,0.7 c-2,0.9-3.5,2.4-4.9,4.1c-0.1,0.1-0.1,0.2,0,0.3c1.1,1.4,2.4,2.6,3.9,3.5C9,16.5,10.4,16.9,12.1,17z" />
			<path d="M12,16.2c-2.3,0-4.2-1.9-4.2-4.2c0-2.3,1.9-4.1,4.2-4.1c2.3,0,4.2,1.9,4.2,4.2C16.2,14.3,14.3,16.2,12,16.2z M12,14.5 c1.3,0,2.5-1.1,2.5-2.5c0-1.3-1.1-2.5-2.5-2.5c-1.3,0-2.5,1.1-2.5,2.5C9.5,13.3,10.7,14.5,12,14.5z" />
		</g>
	</svg>
);
export default EyeIcon;
