import { toast } from 'react-toastify'; //React toastify

export const showToast = (message, showType) => {
	switch (showType) {
		case 'success':
			return toast.success(message, {
				position: toast.POSITION.TOP_CENTER,
			});
			break;
		case 'error':
			return toast.error(message, {
				position: toast.POSITION.TOP_CENTER,
			});
			break;
		case 'warn':
			return toast.warn(message, {
				position: toast.POSITION.TOP_CENTER,
			});
			break;
		case 'info':
			return toast.info(message, {
				position: toast.POSITION.TOP_CENTER,
			});
			break;

		default:
			break;
	}
};
