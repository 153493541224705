import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../util/index';
import axios from 'axios';
import Select from 'react-select';

import UserProfileIcon from '../Icons/UserProfileIcon';
import CrossIcon from '../icons/CrossIcon';
import { saveImageApi, uploadImageApi, cropListApi } from '../util/envConfig';
import CloseIcon from '../icons/CloseIcon';
import TickIcon from '../icons/TickIcon';
import DustbinIcon from '../icons/DustbinIcon';

const AgriCop = () => {
	const [disease, setDisease] = useState('');
	const [crop, setCrop] = useState('');
	const [otherCrop, setOtherCrop] = useState('');
	const [cropVariety, setCropVariety] = useState('');
	const [allImages, setAllImages] = useState([]);
	const [isUploading, setIsUploading] = useState(false);
	const [isUploadSuccessful, setIsUploadSuccessful] = useState(false);

	const [imageKey, setImageKey] = useState(0);
	const [isOtherSelected, setIsOtherSelected] = useState(false);
	const [cropOptions, setCropOptions] = useState([]);
	const [loadingCropOptions, setLoadingCropOptions] = useState(true);
	const [resetSelect, setResetSelect] = useState(false);

	const storedUserData = localStorage.getItem('userData');
	const userData = JSON.parse(storedUserData);

	const navigate = useNavigate();

	useEffect(() => {
		const fetchCropOptions = async () => {
			try {
				const response = await axios.get(cropListApi, {
					headers: {
						Authorization: `Bearer ${userData?.accessToken}`,
					},
				});
				if (response.data) {
					const options = response?.data.map((crop) => ({
						value: crop?.cropName,
						label: crop?.cropName,
					}));
					options.push({ value: 'other', label: 'Other' });
					setCropOptions(options);
					setLoadingCropOptions(false);
				}
			} catch (error) {
				console.error('Error fetching crop options:', error);
			}
		};

		fetchCropOptions();
	}, []);

	const handleImageChange = (e) => {
		const selectedImages = e.target.files;
		const imageArray = Array.from(selectedImages);

		setAllImages((prevImages) => {
			const updatedImages = [...prevImages, ...imageArray];
			return updatedImages;
		});
	};

	const handleClearImage = (imageToRemove) => {
		const updatedImage = allImages.filter((image) => image !== imageToRemove);

		setAllImages([...updatedImage]);

		const fileInput = document.getElementById('fileInput');
		if (fileInput) {
			fileInput.value = null;
		}
	};

	const handleClearAllImages = () => {
		setAllImages([]);

		const fileInput = document.getElementById('fileInput');
		if (fileInput) {
			fileInput.value = null;
		}
	};

	const handleCombinedActions = async (e) => {
		e.preventDefault();
		try {
			if ((!crop && !otherCrop) || !disease || allImages.length === 0) {
				showToast(
					'Please fill in all mandatory fields and select at least one image.',
					'error'
				);
				return;
			}
			if (allImages && allImages.length > 8) {
				showToast('You can upload a maximum of 8 images.', 'error');
				return;
			}

			setIsUploading(true);

			const formData = new FormData();

			allImages.forEach((binaryImage, index) => {
				formData.append(
					'images',
					new Blob([binaryImage], { type: 'image/jpeg' }),
					`image_${index}.jpg`
				);
			});

			const uploadResponse = await axios.post(uploadImageApi, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${userData.accessToken}`,
				},
			});

			if (!Array.isArray(uploadResponse?.data)) {
				throw new Error('Invalid response from image upload API.');
			}

			const previewUrls = uploadResponse?.data.map((item) => item?.imageUri);

			const submitResponse = await axios.post(
				saveImageApi,
				{
					media: previewUrls.map((url, index) => ({
						type: 'image',
						url,
					})),
					userId: userData?.userId,
					cropName: otherCrop ? otherCrop : crop,
					cropVariety: cropVariety,
					disease: disease,
					description: '',
					additionalInfo: {},
				},
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${userData.accessToken}`,
					},
				}
			);

			if (submitResponse.status === 200) {
				showToast('Image(s) Uploaded Successfully', 'success');
				setDisease('');
				setCrop('');
				setCropVariety('');
				setOtherCrop('');
				setAllImages([]);
				setImageKey(imageKey + 1);
				setIsOtherSelected(false);
				setResetSelect(!resetSelect);
			} else {
				throw new Error(`Request failed with status: ${submitResponse.status}`);
			}
		} catch (error) {
			showToast('Error While Adding Image', 'error');
			console.error('Combined API request error: ', error);
		} finally {
			setIsUploading(false);
		}
	};

	const navigateToUserProfile = () => {
		navigate('/user-profile');
	};

	return (
		<div className="bg-sfBorder">
			<div className="w-full xl:w-[1200px] mx-auto px-4 md:px-0 py-5 md:py-8 flex flex-col">
				<div className="mb-5">
					<button
						className="bg-sfSecondaryColor hover:bg-mediumGreen cursor-pointer text-sm text-white font-medium py-2.5 px-3 rounded-md"
						onClick={() => navigate(-1)}
					>
						Back
					</button>
				</div>
				<div className="w-full md:w-5/12 mx-auto py-5 bg-white shadow-xl rounded-2xl">
					<div className="mb-4 px-5">
						<h3 className="text-2xl font-medium text-black">Upload Image</h3>
					</div>
					<div className="mb-4 py-4 px-5 flex flex-col bg-dcBgMediumDarkGreen bg-opacity-10">
						<div className="mb-2.5">
							<h6 className="text-base font-semibold text-black">
								Move closer to damaged area of plant
							</h6>
							<p className="text-sm font-normal text-black">
								Make sure you are close enough to capture the problem
							</p>
						</div>
						<div className="flex flex-row items-center">
							<div className="flex justify-center items-center w-full md:w-1/2 pe-2">
								<div className="bg-white border-2 border-redColorLine relative">
									<img
										src="blurredImg.jpg"
										alt="Blur image"
										className="w-full h-full"
									/>
									<div className="w-8 h-8 bg-redColorLine rounded-es-lg absolute flex items-center justify-center top-0 right-0">
										<CloseIcon fill="white" />
									</div>
								</div>
							</div>
							<div className="flex justify-center items-center w-full md:w-1/2 ps-2">
								<div className="bg-white border-2 border-sfPrimaryColor relative">
									<img
										src="clarityImg.jpg"
										alt="Clear image"
										className="w-full h-full"
									/>
									<div className="w-8 h-8 bg-sfPrimaryColor rounded-es-lg absolute flex items-center justify-center top-0 right-0">
										<TickIcon fill="white" />
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="px-5">
						<div className="mb-4">
							<label
								htmlFor="cropName"
								className="block text-sm font-normal text-black mb-1"
							>
								Crop Name
								<span style={{ color: 'red' }}>*</span>
							</label>

							{loadingCropOptions ? (
								'Loading crop options...'
							) : (
								<Select
									key={resetSelect}
									options={cropOptions}
									value={cropOptions.find((option) => option.value === crop)}
									onChange={(selectedOption) => {
										setIsOtherSelected(selectedOption.value === 'other');
										setCrop(
											selectedOption.value === 'other'
												? ''
												: selectedOption.value
										);
									}}
									className="w-full text-sm font-normal text-black mb-4"
								/>
							)}
							{isOtherSelected && (
								<div className="mb-4">
									<label
										htmlFor="otherCrop"
										className="block text-sm font-normal text-black mb-1"
									>
										Other Crop Name
										<span style={{ color: 'red' }}>*</span>
									</label>
									<input
										type="text"
										id="otherCrop"
										value={otherCrop}
										onChange={(e) => setOtherCrop(e.target.value)}
										className="w-full h-11 py-1 px-3 border border-borderColor rounded-md text-sm font-normal text-black"
									/>
								</div>
							)}
						</div>

						<div className="mb-4">
							<label
								htmlFor="cropVariety"
								className="block text-sm font-normal text-black mb-1"
							>
								Crop Variety
							</label>
							<input
								type="text"
								id="cropVariety"
								value={cropVariety}
								onChange={(e) => setCropVariety(e.target.value)}
								className="w-full h-11 py-1 px-3 border border-borderColor rounded-md text-sm font-normal text-black"
							/>
						</div>

						<div className="mb-4">
							<label className="text-sm font-normal text-black">
								Upload Multiple Images
								<span style={{ color: 'red' }}>*</span>
							</label>
							<div className="relative mt-1">
								<label
									htmlFor="fileInput"
									className="custom-file-upload w-full h-11 py-1 px-3 border border-borderColor rounded-md text-base font-semibold text-mediumGreen"
								>
									{allImages.length === 0 ? (
										'Select Image(s)'
									) : (
										<>
											{/* <span className="text-blue-500">+ Add More </span>
											<span>{`${allImages.length} file(s) selected`}</span>
											<span>
												{allImages.length > 8 && (
													<span className="text-red ml-2">
														(Maximum 8 images allowed)
													</span>
												)}
											</span> */}

											{allImages.length < 8 && (
												<span className="text-sfSecondaryColor mr-1">
													+ Add More
												</span>
											)}
											<span>{`${allImages.length} file(s) selected`}</span>
											{allImages.length > 8 && (
												<span className="text-redColorLine ml-2">
													(Maximum 8 images allowed)
												</span>
											)}
										</>
									)}
								</label>

								<input
									id="fileInput"
									type="file"
									accept="image/*"
									multiple
									onChange={handleImageChange}
									style={{ display: 'none' }} // Hide the actual file input
									disabled={allImages.length >= 8}
								/>
								{allImages.length > 0 && (
									<button
										className="absolute right-[10px] top-[7px] p-0.5 cursor-pointer w-[30px] h-[30px] flex justify-center items-center bg-white rounded-full border border-borderColor"
										onClick={() => handleClearAllImages()}
									>
										<DustbinIcon className="w-5 h-5" fill="#d53737" />
									</button>
								)}
							</div>

							<div className="w-full flex flex-row bg-white flex-wrap max-h-40 overflow-auto scroll-smooth scrollbar mt-0.5">
								{allImages.map((image, index) => (
									<div
										key={index}
										className="relative mb-1.5 me-1.5 bg-white overflow-hidden rounded-md border border-sfBorder p-0.5 w-1/3 h-40 flex justify-center items-center"
									>
										<img
											key={index}
											src={URL.createObjectURL(image)}
											alt={`Image ${index + 1}`}
											className="w-full h-auto"
										/>
										<button
											onClick={() => handleClearImage(image)}
											className="absolute top-1 right-1 cursor-pointer bg-white w-7 h-7 flex justify-center items-center bg-opacity-90 rounded-full shadow-md border border-borderColor"
										>
											<CloseIcon className="w-[14px] h-[14px]" />
										</button>
									</div>
								))}
							</div>
						</div>

						<div className="mb-4">
							<label
								htmlFor="disease"
								className="block text-sm font-normal text-black mb-1"
							>
								Disease / Pest Name
								<span style={{ color: 'red' }}>*</span>
							</label>
							<input
								type="text"
								id="disease"
								value={disease}
								onChange={(e) => setDisease(e.target.value)}
								className="w-full h-11 py-1 px-3 border border-borderColor rounded-md text-sm font-normal text-black"
							/>
						</div>

						<div className="mb-4">
							<button
								onClick={(e) => handleCombinedActions(e)}
								type="submit"
								className={`w-full bg-footerColor2 hover:bg-green500 text-sm font-medium text-white py-2.5 px-3 rounded-md ${
									isUploading || isUploadSuccessful ? 'cursor-not-allowed' : ''
								}`}
								disabled={isUploading || isUploadSuccessful}
							>
								{isUploading ? 'Uploading...' : 'Submit'}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AgriCop;
