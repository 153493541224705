import React from 'react';
const EyeHideIcon = ({
	height = 24,
	width = 24,
	fill = '',
	className = '',
	strokeWidth = '',
}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		height={height}
		width={width}
		fill={fill}
		className={className}
		strokeWidth={strokeWidth}
	>
		<g>
			<path d="M22,12c0,0.2-0.1,0.5-0.2,0.7c-0.8,1.6-1.9,3-3.3,4.2c-0.1,0.1-0.1,0.2,0,0.3c0.8,0.8,1.6,1.5,2.3,2.3c0.4,0.4,0.4,1,0,1.4 c-0.4,0.3-0.9,0.3-1.3-0.1c-0.4-0.4-0.8-0.8-1.2-1.2c-5-5-10.1-10.1-15.1-15.1C3,4.3,2.8,4,2.9,3.7C2.9,3.3,3.1,3,3.5,2.9 c0.4-0.1,0.7,0,1,0.2C5.1,3.8,5.7,4.4,6.3,5c0.3,0.3,0.5,0.5,0.8,0.8c0.1,0.1,0.2,0.1,0.3,0c1.1-0.6,2.3-0.9,3.5-1.1 C13.1,4.5,15.2,5,17,6.1c2,1.2,3.4,2.9,4.6,4.9C21.8,11.3,22,11.6,22,12z M12.1,8.3c0,0,0.2,0,0.3,0c2.5,0.2,4.1,3.1,2.9,5.3 c-0.1,0.1-0.1,0.2,0,0.3c0.5,0.5,1.1,1,1.6,1.6c0.1,0.1,0.2,0.1,0.3,0c1.2-1,2.1-2.1,2.8-3.4C20,12,20,12,20,11.8 c-0.2-0.4-0.5-0.8-0.8-1.2c-0.9-1.3-2-2.3-3.4-3.1c-1.3-0.7-2.7-1-4.2-1c-1,0-1.9,0.3-2.8,0.6c-0.1,0.1-0.2,0.1,0,0.2 C9.2,7.9,9.6,8.3,10,8.7c0.1,0.1,0.2,0.1,0.3,0C10.8,8.5,11.4,8.3,12.1,8.3z M13.8,12c0-1.1-0.9-1.9-2-1.8c-0.1,0-0.1,0-0.2,0.1 c0,0.1,0.1,0.1,0.1,0.1c0.6,0.6,1.2,1.2,1.7,1.7c0.1,0.1,0.2,0.2,0.3,0.2C13.8,12.3,13.8,12.1,13.8,12z" />
			<path d="M11.8,19.3c-2.4,0-4.6-1-6.6-2.7c-1.2-1.1-2.2-2.4-3-3.9c-0.3-0.5-0.3-1,0-1.5C2.7,10.3,3.3,9.4,4,8.6 c0.4-0.4,0.9-0.5,1.3-0.2c0.4,0.3,0.5,0.9,0.1,1.4c-0.5,0.6-1,1.3-1.4,2c-0.1,0.1-0.1,0.3,0,0.4c1,1.8,2.3,3.3,4.2,4.3 c1,0.6,2.1,0.9,3.3,0.9c0.8,0.1,1.5,0,2.2-0.1c0.5-0.1,1,0.2,1.1,0.7c0.1,0.5-0.2,1-0.7,1.1C13.4,19.3,12.7,19.3,11.8,19.3z" />
		</g>
	</svg>
);
export default EyeHideIcon;
