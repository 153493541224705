// ProgressBar.js

import React from 'react';

const ProgressBar = ({ value }) => {
	const percentage = Math.round(value * 100);

	return (
		<div className="relative w-full h-5 bg-gray-300 rounded-md overflow-hidden p-0.5 flex items-center justify-center">
			<div
				style={{ width: `${value * 100}%` }}
				className="absolute top-0 left-0 h-full bg-green-500 transition-all duration-500 rounded-md"
			></div>
			<p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-black text-sm font-medium">
				{percentage}%
			</p>
		</div>
	);
};

export default ProgressBar;
