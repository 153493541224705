import React from 'react';
const CloseIcon = ({
	height = 24,
	width = 24,
	fill = 'black',
	className = '',
	strokeWidth = '',
}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		height={height}
		width={width}
		fill={fill}
		className={className}
		strokeWidth={strokeWidth}
	>
		<path d="M19.7,22c-0.1,0-0.2-0.1-0.2-0.2c-1.5-1.5-2.9-2.9-4.4-4.4c-1-1-1.9-1.9-2.9-2.9c-0.2-0.2-0.2-0.2-0.4,0 C9.4,17,7,19.4,4.6,21.8c-0.3,0.3-0.3,0.3-0.5,0c-0.6-0.6-1.3-1.3-1.9-1.9c-0.2-0.2-0.2-0.3,0-0.4c2.4-2.4,4.8-4.8,7.2-7.2 c0.2-0.2,0.2-0.2,0-0.5C7,9.4,4.6,6.9,2.2,4.5c-0.2-0.2-0.2-0.2,0-0.5c0.6-0.6,1.3-1.3,1.9-1.9C4.3,2,4.4,2,4.5,2.1 C6.9,4.6,9.3,7,11.8,9.4c0.2,0.2,0.2,0.2,0.5,0C14.6,7,17,4.6,19.5,2.2c0.2-0.2,0.2-0.2,0.5,0c0.6,0.6,1.3,1.3,1.9,1.9 c0.2,0.2,0.2,0.3,0,0.4c-2.4,2.4-4.8,4.8-7.2,7.2c-0.2,0.2-0.2,0.2,0,0.5c2.4,2.4,4.8,4.8,7.2,7.2c0.2,0.2,0.2,0.2,0,0.5 c-0.6,0.6-1.3,1.3-1.9,1.9C19.8,21.9,19.8,22,19.7,22z" />
	</svg>
);
export default CloseIcon;
