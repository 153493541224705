import React from 'react';

const UserProfileIcon = (props) => {
	const {
		height = 20,
		width = 20,
		fill = '#e0e1e8',
		className = '',
		stroke = '#e0e1e8',
	} = props;
	return (
		<svg viewBox={`0 0 ${height} ${width}`} className={className}>
			<defs>
				<clipPath id="clip-path">
					<path
						d="M5.6,6.4A3.2,3.2,0,1,0,2.4,3.2,3.2,3.2,0,0,0,5.6,6.4Zm2.238.8H7.417a4.349,4.349,0,0,1-3.642,0H3.358A3.358,3.358,0,0,0,0,10.552v1.039a1.2,1.2,0,0,0,1.2,1.2H9.993a1.2,1.2,0,0,0,1.2-1.2V10.552A3.358,3.358,0,0,0,7.834,7.195Z"
						fill="#e0e1e8"
					/>
				</clipPath>
				<clipPath>
					<rect width={20} height={20} />
				</clipPath>
			</defs>
			<g>
				<rect width={20} height={20} fill="none" />
				<g transform="translate(49.494 -40.981)">
					<g
						transform="translate(-47.494 42.981)"
						fill="none"
						stroke={stroke}
						strokeWidth={1}
					>
						<circle cx={8} cy={8} r={8} stroke="none" />
						<circle cx={8} cy={8} r={7.5} fill="none" />
					</g>
					<g transform="translate(-44.851 45.578)" clipPath="url(#clip-path)">
						<path
							d="M7.745,0A7.745,7.745,0,1,1,0,7.745,7.745,7.745,0,0,1,7.745,0Z"
							transform="translate(-2.149 -2.7)"
							fill={fill}
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default UserProfileIcon;
