import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../util/index';
import Select from 'react-select';
import axios from 'axios';

import {
	uploadArticleApi,
	saveArticleApi,
	categoriesListApi,
} from '../util/envConfig';

const ArticlePage = () => {
	const [categoryOptions, setCategoryOptions] = useState([]);
	const [loadingCategoryOptions, setLoadingCategoryOptions] = useState(true);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);
	const [title, setTitle] = useState('');

	const storedUserData = localStorage.getItem('userData');
	const userData = JSON.parse(storedUserData);

	const handleCategoryChange = (selectedOption) => {
		setSelectedCategory(selectedOption);
	};

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		setSelectedFile(file);
		if (
			file &&
			file.type !==
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
		) {
			showToast('Please upload a .docx file.', 'error');
			document.getElementById('fileInput').value = '';
		}
	};

	const handleReset = () => {
		setSelectedCategory(null);
		setSelectedFile(null);
		setTitle('');
		document.getElementById('fileInput').value = '';
	};

	useEffect(() => {
		const fetchCategoryOptions = async () => {
			try {
				const response = await axios.get(categoriesListApi, {
					headers: {
						Authorization: `Bearer ${userData?.accessToken}`,
					},
				});
				if (response.data) {
					const options = response?.data.map((category) => ({
						value: category?.id,
						label: category?.topicName,
					}));

					setCategoryOptions(options);
					setLoadingCategoryOptions(false);
				}
			} catch (error) {
				console.error('Error fetching Category options:', error);
			}
		};

		fetchCategoryOptions();
	}, []);

	const handleUpload = async () => {
		try {
			if (!selectedCategory || !selectedFile || !title) {
				showToast('Please fill in all required fields.', 'error');
				return;
			}
			const formData = new FormData();
			formData.append('file', selectedFile);

			const uploadResponse = await axios.post(uploadArticleApi, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${userData?.accessToken}`,
				},
			});

			const url = uploadResponse.data.url;
			const payload = {
				userId: userData?.userId,
				categoryId: selectedCategory?.value,
				title: title,
				url,
				additionalInfo: {},
			};

			const saveResponse = await axios.post(saveArticleApi, payload, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userData.accessToken}`,
				},
			});

			handleReset();
			showToast('File uploaded successfully!', 'success');
		} catch (error) {
			console.error('Upload error:', error);
			showToast('Error uploading file. Please try again.', 'error');
		}
	};

	const navigate = useNavigate();

	return (
		<div className="bg-sfBorder">
			<div className="w-full xl:w-[1200px] mx-auto px-4 md:px-0 py-5 md:py-8 flex flex-col">
				<div className="mb-5">
					<button
						className="bg-sfSecondaryColor hover:bg-mediumGreen cursor-pointer text-sm text-white font-medium py-2.5 px-3 rounded-md"
						onClick={() => navigate(-1)}
					>
						Back
					</button>
				</div>

				<div className="min-h-screen flex items-center">
					<div className="w-full md:w-5/12 mx-auto p-5 bg-white shadow-xl rounded-2xl">
						<h3 className="text-2xl font-medium text-black mb-4">
							Upload Article
						</h3>
						<div className="mb-4">
							<label
								htmlFor="category"
								className="block text-sm font-normal text-black mb-1"
							>
								Select Article Category
								<span style={{ color: 'red' }}>*</span>
							</label>

							{loadingCategoryOptions ? (
								'Loading Category options...'
							) : (
								<Select
									options={categoryOptions}
									value={selectedCategory}
									onChange={handleCategoryChange}
									className="w-full text-sm font-normal text-black"
								/>
							)}
						</div>
						<div className="mb-4">
							<label
								htmlFor="title"
								className="block text-sm font-normal text-black mb-1"
							>
								Title
								<span style={{ color: 'red' }}>*</span>
							</label>
							<input
								type="text"
								id="title"
								value={title}
								onChange={(e) => setTitle(e.target.value)}
								className="w-full h-11 py-1 px-3 border border-borderColor rounded-md text-sm font-normal text-black"
							/>
						</div>

						{/* <div className="mb-4">
							<label
								htmlFor="fileInput"
								className="block text-sm font-normal text-black mb-1"
							>
								Upload Article
								<span className="text-gray-500 text-xs block">
									(Please upload a .docx file)
								</span>
								<span style={{ color: 'red' }}>*</span>
							</label>
							<input
								type="file"
								id="fileInput"
								accept=".docx"
								onChange={handleFileChange}
							/>
						</div> */}

						<div className="mb-4">
							<label className="block text-sm font-normal text-black mb-1">
								Select File (.docx)
								<span style={{ color: 'red' }}>*</span>
								<span className="text-blue-500 text-xs block">
									(Please upload a .docx file)
								</span>
							</label>
							<input
								type="file"
								id="fileInput"
								accept=".docx"
								onChange={handleFileChange}
								className="custom-file-upload w-full h-11 py-1 px-3 border border-borderColor rounded-md text-sm font-normal text-black"
							/>
						</div>

						<div className="flex justify-end ">
							<button
								type="button"
								className="w-full md:w-1/2 bg-disableText hover:bg-opacity-90 text-sm font-medium text-white py-2.5 px-3 rounded-md mr-2"
								onClick={handleReset}
							>
								Reset
							</button>
							<button
								type="button"
								className="w-full md:w-1/2 bg-footerColor2 hover:bg-green500 text-sm font-medium text-white py-2.5 px-3 rounded-md ml-2"
								onClick={handleUpload}
							>
								Upload
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ArticlePage;
