import React from 'react';
import { useNavigate } from 'react-router-dom';
import UserProfileIcon from '../Icons/UserProfileIcon';
import BighaatSvgLogo from '../Icons/BighaatSvgLogo';

const Header = ({ userProfileStyle = '' }) => {
	const navigate = useNavigate();
	const navigateToUserProfile = () => {
		navigate('/user-profile');
	};

	return (
		<div className="bg-footerColor2 px-4 py-2.5 flex flex-row items-center justify-between">
			<div className="w-9/12">
				<h2 className="text-2xl font-semibold text-white">
					{/* <BighaatSvgLogo /> */}
					BigHaat
				</h2>
			</div>
			<div className={`w-3/12 justify-end flex ${userProfileStyle}`}>
				<button
					onClick={navigateToUserProfile}
					className="flex flex-row items-center"
				>
					<UserProfileIcon className="w-8 h-8 mr-1.5" />
				</button>
			</div>
		</div>
	);
};

export default Header;
