import React from 'react';
const DustbinIcon = ({
	height = 24,
	width = 24,
	fill = 'black',
	className = '',
	strokeWidth = '',
}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		height={height}
		width={width}
		fill={fill}
		className={className}
		strokeWidth={strokeWidth}
	>
		<g>
			<path d="M19.2,13.8c0,1.9,0,3.7,0,5.6c0,1.4-1.1,2.6-2.6,2.6c-3.1,0-6.1,0-9.2,0c-1.4,0-2.5-1-2.5-2.4c-0.1-1.3,0-2.7,0-4 c0-2.5,0-5,0-7.5c0-0.2,0-0.4-0.2-0.5C4.3,7.3,4.1,7,4.1,6.7C4,6.2,4,5.7,4.1,5.2C4.2,4.5,4.8,4,5.5,4c1,0,2.1,0,3.1,0 c0.5,0,0.4,0,0.4-0.4c0-0.8,0.5-1.4,1.3-1.5c0.1,0,0.2,0,0.3,0c0.9,0,1.8,0,2.7,0c0.9,0,1.6,0.7,1.6,1.5C14.9,4,14.9,4,15.4,4 c1,0,2,0,3,0C19.3,4,20,4.6,20,5.6c0,0.4,0,0.7,0,1.1c-0.1,0.4-0.3,0.7-0.6,1c-0.1,0.1-0.2,0.2-0.2,0.4 C19.2,9.9,19.2,11.9,19.2,13.8z M12,7.9c-1.9,0-3.9,0-5.8,0C6,7.9,5.9,8,5.9,8.2c0,3.7,0,7.5,0,11.2c0,0.1,0,0.3,0,0.4 c0.2,0.7,0.7,1.1,1.5,1.1c3.1,0,6.1,0,9.2,0c0.9,0,1.6-0.6,1.6-1.6c0-3.7,0-7.4,0-11.1c0-0.3,0-0.3-0.3-0.3 C15.9,7.9,13.9,7.9,12,7.9z M12,5C9.9,5,7.8,5,5.6,5C5.3,5,5.1,5.2,5.1,5.5c0,0.3,0,0.6,0,0.9c0,0.3,0.2,0.5,0.5,0.5 c4.3,0,8.5,0,12.8,0c0.4,0,0.5-0.2,0.6-0.5c0-0.2,0-0.4,0-0.7c0-0.5-0.2-0.6-0.6-0.6C16.2,5,14.1,5,12,5z M12,4c0.6,0,1.1,0,1.7,0 c0.1,0,0.2,0,0.2-0.1c0.2-0.4-0.1-0.8-0.6-0.8c-0.8,0-1.7,0-2.5,0c-0.5,0-0.7,0.2-0.7,0.7C10.1,4,10.2,4,10.4,4C10.9,4,11.5,4,12,4 z" />
			<path d="M11.5,14.4c0-1.2,0-2.4,0-3.7c0-0.1,0-0.3,0-0.4c0.1-0.2,0.3-0.4,0.5-0.4c0.2,0,0.4,0.1,0.5,0.4c0,0.1,0,0.3,0,0.4 c0,2.5,0,4.9,0,7.4c0,0,0,0,0,0.1c0,0.4-0.2,0.6-0.5,0.6c-0.3,0-0.5-0.2-0.5-0.6c0-1.2,0-2.3,0-3.5C11.5,14.7,11.5,14.5,11.5,14.4z " />
			<path d="M16.1,14.4c0,1.3,0,2.6,0,3.8c0,0.3-0.1,0.5-0.4,0.6c-0.3,0-0.5-0.1-0.6-0.3c0-0.1,0-0.3,0-0.4c0-2.5,0-4.9,0-7.4 c0-0.1,0-0.1,0-0.2c0-0.3,0.3-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5C16.1,11.8,16.1,13.1,16.1,14.4z" />
			<path d="M7.9,14.4c0-1.3,0-2.6,0-3.8c0-0.2,0-0.3,0.2-0.5c0.3-0.3,0.7-0.1,0.8,0.2c0,0.1,0,0.2,0,0.4c0,2.5,0,5,0,7.5 c0,0.1,0,0.2,0,0.3c-0.1,0.3-0.3,0.5-0.6,0.4c-0.3,0-0.5-0.2-0.5-0.5c0-1,0-2.1,0-3.1C7.9,15,7.9,14.7,7.9,14.4z" />
		</g>
	</svg>
);
export default DustbinIcon;
